var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-box"},[_c('el-table',{ref:"multipleTable",attrs:{"data":_vm.tableData,"cell-class-name":_vm.cellcb,"height":"100%","header-cell-style":{
            background: '#FAFAFA',
            color: '#333333',
            'font-size': '14px',
            'font-weight': '500',
        }}},[_c('el-table-column',{attrs:{"prop":"createTime","label":"创建时间","show-overflow-tooltip":"","width":"93"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticStyle:{"color":"#2370eb"},attrs:{"type":"text"},on:{"click":function($event){return _vm.onDetail(scope.row)}}},[_vm._v(_vm._s(_vm.$tableDataHandle.createTime({ createTime: scope.row.createTime, })))])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                        (scope.row.lineContactBOList &&
                            scope.row.lineContactBOList.length > 0) ||
                        (scope.row.customerMainContactBOList &&
                            scope.row.customerMainContactBOList.length > 0)
                    )?_c('div',[(
                            scope.row.lineContactBOList &&
                            scope.row.lineContactBOList.length > 0
                        )?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"bottom"}},[_c('div',{staticClass:"way_box",attrs:{"slot":"content"},slot:"content"},_vm._l((scope.row.lineContactBOList),function(item){return _c('p',{key:item.id},[_c('span',{staticClass:"way_box_fs"},[_c('em',[_vm._v(" "+_vm._s(_vm.$tableDataHandle.contactWay( item )))]),_vm._v(" "+_vm._s(item.contactContent)+" ")]),(item.number > 1)?_c('span',{staticClass:"copy2",on:{"click":function($event){return _vm.telSearch(item)}}},[_c('i',{staticClass:"iconfont icon-copy-rect-full"}),_vm._v(" "+_vm._s(item.number)+" ")]):_vm._e()])}),0),_c('div',{staticStyle:{"position":"relative"}},[_c('span',[_c('i',{class:_vm.$tableDataHandle.contactWayColor(
                                            _vm.returnWay(scope.row)
                                        ).icon,style:({
                                        color: _vm.$tableDataHandle.contactWayColor(
                                            _vm.returnWay(scope.row)
                                        ).color,
                                    })}),_vm._v("   "+_vm._s(_vm.returnWay(scope.row).contactContent)+" ")]),(_vm.returnWay(scope.row).number > 1)?_c('span',{staticClass:"copy",on:{"click":function($event){_vm.telSearch(_vm.returnWay(scope.row))}}},[_c('i',{staticClass:"iconfont icon-copy-rect-full"}),_vm._v(" "+_vm._s(_vm.returnWay(scope.row).number)+" ")]):_vm._e()])]):(
                            scope.row.customerMainContactBOList &&
                            scope.row.customerMainContactBOList.length > 0
                        )?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"bottom"}},[_c('div',{staticClass:"way_box",attrs:{"slot":"content"},slot:"content"},_vm._l((scope.row
                                    .customerMainContactBOList),function(item){return _c('p',{key:item.id},[_c('span',{staticClass:"way_box_fs"},[_c('em',[_vm._v(" "+_vm._s(_vm.$tableDataHandle.contactWay( item )))]),_c('em',{staticClass:"contactContent"},[_vm._v(_vm._s(item.contactContent))]),_vm._v("    "+_vm._s(item.contactName)+" ")])])}),0),_c('div',{staticStyle:{"position":"relative"}},[_c('span',[_c('i',{class:_vm.$tableDataHandle.contactWayColor(
                                            scope.row
                                                .customerMainContactBOList[0]
                                        ).icon,style:({
                                        color: _vm.$tableDataHandle.contactWayColor(
                                            scope.row
                                                .customerMainContactBOList[0]
                                        ).color,
                                    })}),_vm._v("   "+_vm._s(scope.row.customerMainContactBOList[0] .contactContent)+" ")])])]):_vm._e()],1):_c('div',[_vm._v("- -")])]}}])},[_c('template',{slot:"header"},[_c('div',[_vm._v("  联系方式")])])],2),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"线索产品","show-overflow-tooltip":"","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(_vm.$tableDataHandle.productCategory( scope.row.productClue ))+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"客户名称","width":"239"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.companyName || '- -')+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"客户","show-overflow-tooltip":"","width":"239"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                        scope.row.customerBOList &&
                        scope.row.customerBOList.length > 0
                    )?_c('div',[_vm._v(" "+_vm._s(scope.row.customerBOList[0].companyName || '- -')+" ")]):_c('div',[_vm._v("- -")])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"域名","width":"145px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"bottom"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('p',{staticClass:"domain_item"},[_vm._v(" "+_vm._s(scope.row.companyDomain || '- -')+" ")]),_vm._l((scope.row.lineDomainBOList),function(item){return _c('p',{key:item.id,staticClass:"domain_item"},[_vm._v(" "+_vm._s(item.domain || '- -')+" ")])})],2),_c('div',[_vm._v(" "+_vm._s(scope.row.companyDomain || '- -')+" ")])])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"需求","show-overflow-tooltip":"","width":"173"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.remark || '- -')+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"prop":"remark","label":"负责人","width":"55","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.followAdminName || '- -')+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"prop":"remark","label":"状态","width":"61","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(_vm.$tableDataHandle.clueStatus(scope.row.status))+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"prop":"remark","label":"操作","fixed":"right","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                        _vm.btnP.disUser &&
                        (scope.row.status == 14 || scope.row.status == 5) &&
                        _vm.departmentId == scope.row.followDepartmentId
                    )?_c('el-button',{staticStyle:{"color":"#2370eb"},attrs:{"type":"text"},on:{"click":function($event){return _vm.onDistributionUser(scope.row)}}},[_vm._v(" 分配负责人 ")]):_vm._e()]}}])}),_c('div',{staticClass:"empty",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":require("@/assets/zanwushuju.png")}}),_c('span',{staticStyle:{"display":"block"}},[_vm._v("暂无数据～")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }